.table.shopping-summery {
	background: #fff;
}
.shopping-cart {
	background: #f6f6f6;
	padding: 10px 0;
}
.shopping-summery thead .main-hading{
	padding:0px 50px;
}
.shopping-summery thead {
	background: #ffc107;
	color: #fff;
}
.shopping-summery thead tr th {
	border: none;
	font-weight: 600;
	color: #fff;
	text-align: center;
}
.shopping-summery tbody tr {
	border-bottom: 1px solid #ffc107;
	margin-top: 20px;
}
.shopping-summery tbody tr img {
	border-radius: 0;
	width: 80px;
	height: 80px;
}
.shopping-summery tbody tr:last-child{
	border:none;
}
.shopping-summery tbody .product-name a {
	font-weight: 600;
	color: #282828;
	font-weight: 600;
	font-size: 17px;
	display: -webkit-box;
	-webkit-line-clamp: 1; /* Số dòng chữ tối đa */
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.shopping-cart .table p {
	font-size: 14px;
	color: #666;
}
.shopping-summery tbody .product-name a:hover{
	color:#ffc107;
}
.shopping-summery tbody .product img {
	max-width: 70px;
	border-radius: 100%;
	max-height: 65px;
	border: 1px solid #e6e6e6;
	padding: 4px;
}
.shopping-summery tbody .product:hover img{
	border-color:#ffc107;
	-webkit-transform:rotate(360deg);
	-moz-transform:rotate(360deg);
	transform:rotate(360deg);
}
.shopping-cart .border{
	
}
.shopping-cart .table .remove-icon{
	font-size:16px;
}
.shopping-cart .table td {
	vertical-align: middle;
	border-top: 1px solid #eee;
	padding: 30px;
}
.shopping-summery tbody .price {
	text-align: center;
	white-space: nowrap;
}
.shopping-summery tbody .price span{}
.shopping-cart tbody .qty .input-group {
	/* width: 175px; */
	display: inline-block;
}
.shopping-cart .qty .button {
	display: inline-block;
	position: absolute;
	top: 0;
}
.shopping-cart .qty .button.minus{
	left:0;
	border-radius:0;
	overflow:hidden;
}
.shopping-cart .qty .button.plus {
	right: 0;
	border-radius:0;
	overflow:hidden;
}
.shopping-cart .qty .button .btn {
	padding: 0;
	width: 44px;
	height: 47px;
	line-height: 50px;
	border-radius: 0px;
	background: transparent;
	color: #282828;
	border: none;
	font-size: 12px;
}
.shopping-cart .qty .button .btn:hover{
	color:#ffc107;
}
.shopping-cart .qty .input-number {
	border: 1px solid #eceded;
	width: 100%;
	text-align: center;
	height: 47px;
	border-radius:0;
	overflow: hidden;
	padding: 0px 45px;
}
.shopping-summery tbody .total-amount {
	text-align: center;
	white-space: nowrap;
}
.shopping-summery tbody .total-amount span{}
.shopping-summery tbody .action {
	text-align: center;
}
.shopping-summery tbody .action a:hover{
	color:#ffc107;
}
.shopping-cart .total-amount{
	margin-top:50px;
}
.shopping-cart .total-amount .left{}
.shopping-cart .total-amount .left .coupon{}

.shopping-cart .total-amount .left .coupon form{}
.shopping-cart .total-amount .left .coupon form input {
	/* width: 220px; */
	display: inline-block;
	height: 48px;
	color: #1769b4;
	padding: 0px 20px;
	border: none;
	box-shadow: 0px 0px 5px #0000000a;
}
.shopping-cart .total-amount .left .coupon form .btn {
	display: inline-block;
	height: 48px;
	border: navajowhite;
	margin-left: 4px;
	background: transparent;
	color: #1769b4;
	background: #fff;
	/* box-shadow: 0px 0px 5px #00000012; */
}
.shopping-cart .total-amount .left .coupon form .btn:hover{
	background:#fff;
	color:#ffc107;
}
.shopping-cart .total-amount .left label{
	font-size:22px;
	font-weight:500;
	color:#1769b4;
}
.shopping-cart .total-amount .left .checkbox {
	text-align: left;
	margin: 0;
	margin-top: 20px;
}
.shopping-cart .total-amount .left .checkbox label {
	font-size: 15px;
	font-weight: 400;
	color: #1769b4;
	position: relative;
	padding-left: 30px;
}
.shopping-cart .total-amount .left .checkbox label:hover{
	cursor:pointer;
}
.shopping-cart .total-amount .left .checkbox label input{
	display:none;
}
.shopping-cart .total-amount .left .checkbox label::before {
	position: absolute;
	content: "";
	left: 0;
	top: 4px;
	width: 16px;
	height: 16px;
	border: 1px solid #555555;
	border-radius: 0px;
}
.shopping-cart .total-amount .left .checkbox label::after {
	position: absolute;
	content: "\f00c";
	font-family: "Fontawesome";
	left: 0;
	top: 0;
	width: 22px;
	height: 22px;
	line-height: 24px;
	left: 3px;
	top: 0px;
	opacity: 0;
	visibility: hidden;
	transform: scale(0);
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition: all 0.4s ease;
	display: block;
	font-size: 11px;
}
.shopping-cart .total-amount .left .checkbox label.checked::after{
	opacity:1;
	visibility:visible;
	transform:scale(1);
}
.shopping-cart .total-amount .right {
	/* padding-left: 100px; */
    background-color: var(--bg-main);
}
.shopping-cart .total-amount .right ul{
	padding-top: 10px;
	padding-right: 10px;
}
.shopping-cart .total-amount .right ul li {
	font-size: 15px;
	font-weight: 400;
	color: #1769b4;
	margin-bottom: 12px;
	margin-right: 10px;
	display: block;
}
.shopping-cart .total-amount .right ul li.last {
	padding-top: 12px;
	border-top: 1px solid #c8c8c8;
	color: #1769b4;
	font-size: 15px;
	font-weight: 400;
}
.shopping-cart .total-amount .right ul li span{
	display:inline-block;
	float:right;
}
.shopping-cart .total-amount .right .button5 .btn {
	text-align: center;
	border-radius: 0;
	width: 100%;
	margin-top: 10px;
	height: 46px;
	line-height: 18px;
	font-size: 1rem;
	color: #fff;
    background-color: #ffc107;
	display: flex;
    align-items: center;
    justify-content: center;
	font-weight: 700;
}