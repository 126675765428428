@charset "UTF-8";

/* footer */
.navbar-brand-footer img {
	height: 40px !important;
	margin-top: -15px;
	/* display: block; */
}
.logo_footerl{
	height: 40px !important;
	margin-top: -15px;
	display: block;
}

.main-footer {
	/* background-image: url('../images/footer_bg.png'); */
	background-color: #333333;
	padding: 20px 0px 0px 0px;
	background-size: cover;
	background-position: center;
}

.footer_ {
	display: flex;
	flex-wrap: nowrap;
	/* justify-content: flex-start; */
}

.footer-top {
	padding-bottom: 20px;
	border-bottom: 2px solid #fff;
}

.footer-top h2 {
	/* color: #000; */
	font-size: 1rem;
	font-family: 'Raleway', sans-serif;
	font-weight: 300;
	line-height: 26px;
	padding-top: 0.8em;
	/* color: #fff !important */
}

.text_footer {
	font-size: 1rem;
	font-family: 'Raleway', sans-serif;
	font-weight: 300;
	line-height: 26px;
	padding-top: 0.5em;
}

.form-box {
	position: relative;
	margin-right: 15px;
}

.form-box input {
	width: 100%;
	box-sizing: border-box;
	background: #fff;
	color: #646464;
	min-height: 51px;
	border-radius: 2px;
	padding-left: 30px;
	font-size: 1rem;
	font-family: 'Raleway', sans-serif;
	padding-right: 140px;
	border-radius: 50px;
}


.footer-link-box {
	padding-top: 20px;
	padding-bottom: 20px;
	padding: 20px 0px;
}

.left-f-box {}

.left-f-box h2 {
    color: #fff;
    font-size: 1.2em;
    font-weight: 700;
    text-transform: uppercase;
    border-left: solid var(--menu-btn) 4px;
    line-height: normal;
    margin-left: 10px;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.left-f-box ul {
	margin: 0px;
	/* padding: 0 10px; */
	color: #fff;
	/* font-size: ; */

}
.icon-footer {
    padding:0 10px;
    font-size: 25px
}

.button_footer .btn {
	color: #fff;
	background: #1769b4;
	padding: 10px;
	line-height: initial;
	border: none;
	height: 30px;
	z-index: 0;
	border-radius: 50px;
}
.button_footer .btn:hover{
	background:#ffc107;
	color:#fff;
}
.left-f-box ul li {
	text-align: justify;
	padding-bottom: 10px;
	display: block;
}

.left-f-box ul li a {
	color: #fff;
	font-size: 1rem;
	font-weight: 400;
	text-decoration: none;
	cursor: default;

}

.left-f-box ul li a:hover {
	color: #12345b;
}

.right-f-box {}

.right-f-box h2 {
	color: #fff;
	font-family: 'Raleway', sans-serif;
	font-size: 1.2rem;
	font-weight: 700;
	margin-bottom: 20px;
	text-transform: uppercase;
	padding: 0 0 0 10px;
	margin-left: 20px;
	border-left: solid var(--menu-btn) 4px;
}

.right-f-box ul {
	margin: 0px;
	padding: 0px;
}

.right-f-box ul li {
	padding-bottom: 10px;
}

.right-f-box ul li a {
	color: #000;
	font-family: 'Raleway', sans-serif;
	font-size: 1rem;
	font-weight: 400;
}


/*-- Copyright --*/

.copyright {
	line-height: 35px;
}

.copyright p {

	font-size: 1em;
	color: #000;
	margin: 0;
}

.copyright p img {
	margin-right: 10px;
}

.copyright p a {
	color: #000;
}

.copyright p a:hover {
	text-decoration: underline;
}

.copyright .socials {
	float: right;
	padding: 15px 0px;
}

.copyright .socials {
	margin: 0px;
}

.copyright .socials li {
	margin: 0px;
}

.copyright .socials li a {
	color: #09294c;
	font-size: 18px;
}

.right-flag {
	display: inline-block;
	float: right;
	padding: 8px 0px;
	margin-right: 25px;
}

.right-flag {}

.right-flag li {}

.right-flag li a {
	color: #626262;
}
@media (max-width:800px) {
	.logo_footer{
		height: 40px;
		display: block !important;
		margin-top: 0.1rem !important ;
	}
	.navbar-brand-footer img {
	
	}
	.logo_footerl{
		display: none !important;
	}
}
.logo_footer{
	/* height: 40px; */
	display: none;
}