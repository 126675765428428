/* tab */
.product-area .nav-tabs {
	text-align: center;
	display: inline-block;
	width: 100%;
	border:none;
}
.product-area .nav-tabs .nav-item {
	margin-bottom: -1px;
	display: inline-block;
}
.product-area .nav-tabs li a {

	text-transform: uppercase;
	display: inline-block;
	position: relative;
	margin-right: 5px;
	font-weight: 500;
	background: #fff;
	color: #292929;
	padding: 3px 14px;
	border-radius: 3px;
	font-size: 1rem;
}
.product-area .nav-tabs li:last-child a{
	border-color:transparent;
} 
.product-area .nav-tabs li a i{
	margin-right:10px;
}
.product-area .nav-tabs li a.active,
.product-area .nav-tabs li:hover a{
	background:#ffc107;
	color:#fff;
	border-color:transparent;
}
/* end tab */
.single-product{
	margin-top:50px;
}
.single-product .product-img {
  position: relative;
  overflow: hidden;
  cursor:pointer;
}
.single-product .product-img a {
  display: block;
  position: relative;
}
.single-product .product-img a img {
  /* width: 100%; */
  height: 300px;
  /* width: 300px; */
  object-fit: fill;
}
.single-product .product-img a img.hover-img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.5s ease, transform 2s cubic-bezier(0, 0, 0.44, 1.18), -webkit-transform 2s cubic-bezier(0, 0, 0.44, 1.18);
}
.single-product .product-img a span.price-dec {
	background-color: #f6931d;
	display: inline-block;
	font-size: 11px;
	color: #fff;
	right: 20px;
	top: 20px;
	padding: 1px 16px;
	font-weight: 700;
	border-radius: 0;
	text-align: center;
	position: absolute;
	text-transform: uppercase;
	border-radius: 30px;
	height: 26px;
	line-height: 25px;
	z-index: 1;
}
.single-product .product-img a span.new {
	background-color: #8493ca;
	display: inline-block;
	font-size: 11px;
	color: #fff;
	right: 20px;
	top: 20px;
	padding: 1px 16px;
	font-weight: 700;
	border-radius: 0;
	text-align: center;
	position: absolute;
	text-transform: uppercase;
	border-radius: 30px;
	height: 26px;
	line-height: 24px;
	z-index: 1;
}
.single-product .product-img a span.out-of-stock {
	background-color: #ed1b24;
	display: inline-block;
	font-size: 11px;
	color: #fff;
	right: 20px;
	top: 20px;
	padding: 1px 16px;
	font-weight: 700;
	border-radius: 0;
	text-align: center;
	position: absolute;
	text-transform: uppercase;
	border-radius: 30px;
	height: 26px;
	line-height: 24px;
	z-index: 1;
}
.single-product .product-img .product-action {
	display: inline-block;
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: 99;
	border-radius: 3px;
}
.single-product .product-img .product-action a {
	background-color: transparent;
	color: #1769b4;
	display: block;
	font-size: 16px;
	display: inline-block;
	margin-right: 15px;
	text-align: right;
	height: 52px;
	position: relative;
	top: 2px;
	text-decoration: none;
}
.single-product .product-img .product-action a:last-child{
	margin-right:0;
	border:none;
}
.single-product .product-img .product-action a i {
  line-height: 40px;
  text-decoration: none;
}
.single-product .product-img .product-action a span {
	visibility: hidden;
	position: absolute;
	background: #ffc107 !important;
	color: #fff !important;
	text-align: center;
	padding: 5px 12px;
	z-index: 3;
	opacity: 0;
	-webkit-transition: opacity .6s, margin .3s;
	-o-transition: opacity .6s, margin .3s;
	transition: opacity .6s, margin .3s;
	font-size: 11px;
	right: 0;
	line-height: 14px;
	top: -12px;
	margin-top: -5px;
	margin-right: 0;
	display: inline-block;
	width: 120px;
	border-radius:15px 0 0 15px;
}
.single-product .product-img .button-head .product-action a span::after {
	position: absolute;
	content: "";
	right: 0;
	bottom: -12px;
	border: 6px solid #ffc107;
	border-left:0px solid transparent;
	border-right:6px solid transparent;
	border-bottom:6px solid transparent;
}
.single-product .product-img .product-action a:hover {
	color:#ffc107;
}
.single-product .product-img .product-action a:hover span {
  visibility: visible;
  opacity: 1;
  color:#1769b4;
  background:#fff;
  margin-top: -12px;
}
.single-product .product-img .product-action.pro-action-width-dec a {
  width: 30px;
  height: 30px;
  font-size: 14px;
}
.single-product .product-img .product-action.pro-action-width-dec a i {
  line-height: 30px;
}
.single-product .product-img .product-action.pro-action-width-dec-2 {
  bottom: 45px;
}
.single-product .product-img .product-action-2 {
	position: absolute;
	left: 0;
	bottom: 0;
	text-align: left;
	z-index: 99;
	-webkit-transition: all 250ms ease-out;
	-o-transition: all 250ms ease-out;
	transition: all 250ms ease-out;
}
.single-product .product-img .product-action-2 a {
	display: block;
	background-color: transparent;
	color: #fff;
	text-align: left;
	font-size: 12px;
	font-weight: 700;
	text-transform: uppercase;
	line-height: 1;
	display: inline-block;
	padding-left: 10px;
	text-decoration: none;
}
.single-product .product-img .product-action-2 a:hover {
  color:#000;
}
.single-product .button-head {
	background: var(--menu-btn);
	display: inline-block;
	height: 40px;
	width: 100%;
	position: absolute;
	left: 0;
	bottom: -50px;
	z-index: 9;
	height: 50px;
	line-height: 50px;
	-webkit-transition:all 0.4s ease;
	-moz-transition:all 0.4s ease;
	transition:all 0.4s ease;
}
.single-product:hover .button-head{
	bottom:0;
}
.single-product .product-img .shop-list-quickview {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  z-index: 99;
  margin-top: 20px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all .35s ease 0s;
  -o-transition: all .35s ease 0s;
  transition: all .35s ease 0s;
}
.single-product .product-img .shop-list-quickview a {
  color: #000;
  background-color: #fff;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  font-size: 18px;
}
.single-product .product-img .shop-list-quickview a:hover {
  background-color: #222;
  color: #fff;
}
.single-product .product-img .shop-list-quickview a i {
  line-height: 50px;
}
.single-product .product-content{
	margin-top:20px;
}
.single-product .product-img:hover.default-overlay::before {
  background-color: rgba(38, 38, 38, 0.2);
  z-index: 9;
  -webkit-transition: all 250ms ease-out;
  -o-transition: all 250ms ease-out;
  transition: all 250ms ease-out;
  pointer-events: none;
  opacity: 1;
}
.single-product .product-img:hover.default-overlay.metro-overlay::before {
  background-color: rgba(38, 38, 38, 0.4);
  z-index: 9;
  -webkit-transition: all 250ms ease-out;
  -o-transition: all 250ms ease-out;
  transition: all 250ms ease-out;
  pointer-events: none;
  opacity: 1;
}
.single-product .product-img:hover img.hover-img {
  opacity: 1;
}
.single-product .product-content h3 {
    line-height: 22px;
}
.single-product .product-content a{
	line-height: 22px;
}
.single-product .product-content a h1{
	font-size: 1rem !important;
    font-weight: 500;
    margin: 0;
    text-decoration: none;
    color: #000;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.single-product .product-content a h1:hover{
	color:#ffc107;
}

.single-product .product-content h3 a {

	font-size: 14px;
    font-weight: 500;
    margin: 0;
    text-decoration: none;
    color: #000;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.single-product .product-content h3 a:hover{
	color:#ffc107;
}
.single-product .product-content .product-price {
  margin: 6px 0 0 0;
}
.single-product .product-content .product-price span {
	font-size: 15px;
	font-weight: 500;
}
.single-product .product-content .product-price span.old {
  text-decoration: line-through;
  opacity: .6;
  margin-right: 2px;
  font-style: italic;
  font-size: 0.9rem;
}

/* recent */

/* Recent Post */
.shop-sidebar .single-post {
	position: relative;
	margin-top: 30px;
    padding-bottom: 30px;
	border-bottom: 1px solid #ddd;
}
.shop-sidebar .single-post.first{
	padding-top:0px;
}
.shop-sidebar .single-post:last-child{
	padding-bottom:0px;
	border:none;
}
.shop-sidebar .single-post .image img{
	height: 80px;
	width: 80px;
	position:absolute;
	left:0;
	top:0;
	border-radius:100%;
}
.shop-sidebar .single-post .content{
	padding-left:100px;
}
.shop-sidebar .single-post .content h5 {
	line-height: 18px;
}
.shop-sidebar .single-post .content h5 a {
	color: #222;
	font-weight: 500;
	font-size: 14px;
	font-weight: 500;
	display: block;
	text-decoration: none;
}
.shop-sidebar .single-post .content h5 a:hover{
	color:#ffc107;
}
.shop-sidebar .single-post .content .price {
	display: block;
	color: #1769b4;
	font-weight: 500;
	margin: 5px 0 0px 0;
	text-transform: uppercase;
	font-size: 14px;
}
.shop-sidebar .single-post .reviews li{
	display:inline-block;
}
.shop-sidebar .single-post .reviews li i{
	color:#999;
}
.shop-sidebar .single-post .reviews li.yellow i{
	color:#ffc107;
}
.section-title {
	text-align: center;
	/* margin-bottom: 10px; */
	padding: 1.1rem 0rem;
} .section-title h2 {
	/* font-size: 25px; */
	margin-bottom: 0;
	text-transform: capitalize;
	position: relative;
	color: #2c2d3f;
	font-weight: 700;
	padding-bottom: 15px;
	display: inline-block;
  border-bottom: 5px solid var(--menu-btn);
}
.nav-main{
	margin-top: -1rem;
}
.mota{
	text-align: justify;
	font-size: 1em;
}
.mota .tittle{
	font-weight: 600;
}

  
.product__details__tab .product__details__tab__desc {
    max-height: 20rem;
    overflow: hidden;
    transition: all ease .5s;
    padding-bottom: 0;
}

.product__details__tab .product__details__tab__desc.show {
    max-height: none;
    padding-bottom: 3rem;
    transition: all ease .5s;
}
.product__details__tab .bg-article{
	text-align: center;
}