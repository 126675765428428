@media all and (min-width: 992px) {
	.navbar .nav-item .dropdown-menu{  display:block; opacity: 0;  visibility: hidden; transition:.3s; margin-top:0;  }
	.navbar .nav-item:hover .nav-link{ color: #fff;  }
	.navbar .dropdown-menu.fade-down{ top:80%; transform: rotateX(-75deg); transform-origin: 0% 0%; }
	.navbar .dropdown-menu.fade-up{ top:180%;  }
	.navbar .nav-item:hover .dropdown-menu{ transition: .3s; opacity:1; visibility:visible; top:100%; transform: rotateX(0deg); }
}
.navbar-dark .navbar-nav .nav-link{
	color: #FFF;
	text-transform: uppercase;
}
/* @import url('https://fonts.googleapis.com/css?family=Roboto'); */

body {
	margin: 0;
	padding: 0;
	font-family: 'Roboto', sans-serif;
}

.top-head_ {
	background-color: #333333;
}
/* 
.navigation {
	height: 55px;

	background-color: #292929;
} */

.brand {
	position: absolute;
	/* padding-left: 20px; */
	float: left;
	line-height: 55px;
	/* text-transform: uppercase; */
	/* font-size: 1.4em; */
	color: #FFF;
	font-size: 1rem;
}

.brand a,
.brand a:visited {
	/* color: #ffffff; */
	color: #000;
	text-decoration: none;
}

.nav-container {
	/* max-width: 1000px; */
	/* margin: 0 auto; */
}

nav {
	
	display: flex;
	justify-content: center;
}

nav ul {

	list-style: none;
	margin: 0;
	padding: 0;
	/* display: block; */
	/* position: absolute; */
	z-index: 1;
}

nav ul li {
	float: left;
	position: relative;
	border-radius: 50px;
	z-index: 1;
	/* background-color: rgb(255, 179, 0); */
}

/* nav ul li a,
nav ul li a:visited {
	display: block;
	padding: 0px 10px;
	
	line-height: 55px;
	
	border-radius: 50px;
	text-decoration: none;
} */

/* nav ul li a {
	background: transparent;
	color: #fff;
	font-weight: 600;
} */

/* nav ul li a:hover,
nav ul li a:visited:hover {

	transform: scale(1.2);
	color: #ffffff;
} */

/* .navbar-dropdown li a {
	
	background: var(--toastify-icon-color-warning);
} */
/* 
nav ul li a:not(:only-child):after,
nav ul li a:visited:not(:only-child):after {
	padding-left: 4px;
	content: ' \025BE';
}

nav ul li ul li {
	min-width: 190px;
}

nav ul li ul li a {
	padding: 15px;
	line-height: 20px;
} */

/* .navbar-dropdown {
	position: absolute;
	display: none;
	z-index: 1;
	background: #fff;
	box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.25);
} */

/* Mobile navigation */
/* .nav-mobile {
	display: none;
	position: absolute;
	top: 90px;
	right: 0;
	background: transparent;
	height: 55px;
	width: 70px;
} */

@media only screen and (max-width: 800px) {


	#header .menu .gio_hang {

		/* flex-direction: row !important; */

	}

	.navbar-dropdown {
		/* margin-left: -50px; */
		/* display: none !important; */
	}
	.navbar-nav{
		/* display:none !important; */
	}
	.navbar-toggler:hover .navbar-nav{
		/* display:none !important; */
	}
	#main_nav.active {
		display: block;
		/* Các kiểu hiển thị khác khi phần tử có lớp 'active' */
	  }

	/* nav ul {
		position: absolute;
	} */

	/* .nav-mobile {
		display: block;
	} */

/* 
	nav {
		width: 100%;
		padding: 55px 0 15px;
	} */

	/* nav ul {
		display: none;
		margin-left: 73%;
		background-color: var(--bg-main);
		padding: 0px 1rem;
	} */

	/* nav ul li {
		float: none;
	} */

	/* nav ul li a {
		padding: 15px;
		line-height: 20px;
		
	} */

	/* nav ul li ul li a {
		padding-left: 30px;
	} */

	/* .navbar-dropdown {
		position: static;
	} */

	@media screen and (min-width:800px) {
		.nav-list {
			display: block !important;
		}
	}

	@media screen and (min-width: 570px) and (max-width: 770px) {
		/* nav ul {
			display: none;
			margin-left: 60%;
			background-color: var(--bg-main);
			padding: 0px 1rem;
		} */
	}

	@media screen and (min-width: 380px) and (max-width: 576px) {
		/* nav ul {
			display: none;
			margin-left: 58%;
		
			background-color: #333333;
			padding: 0px 1rem;
		} */
	}

	@media (max-width:576px) {
		.menu .title {
			display: none;
		}
		#header .company .companyinfo .navbar_logo {
			/* width: 36px; */
			display: none;
			height: 60px;
			margin-top: -10px;
		}
	
	
		#header .company .companyinfo .navbar_logo_mobile {
			/* width: 36px; */
			height: 4rem;
			margin-top: -5px;
			display: block !important;
		}

		#header .company .companyinfo h1 {

			font-size: 1.5rem !important;

		}

		#header .menu .gio_hang {
			margin-top: 0.8rem !important;
		}

		nav ul {
			display: none;
			/* margin-left: 62%; */
		}

		.account_home {
			flex-direction: row !important;
			margin-top: 0.25rem !important;
		}

	}

	/* #navbar-toggle {
		position: absolute;
		left: 18px;
		top: 27px;
		cursor: pointer;
		padding: 10px 35px 16px 0px;
	}

	#navbar-toggle span,
	#navbar-toggle span:before,
	#navbar-toggle span:after {
		cursor: pointer;
		border-radius: 1px;
		height: 3px;
		width: 30px;
		background: #ffffff;
		position: absolute;
		display: block;
		content: '';
		transition: all 300ms ease-in-out;
	}

	#navbar-toggle span:before {
		top: -10px;
	}

	#navbar-toggle span:after {
		bottom: -10px;
	}

	#navbar-toggle.active span {
		background-color: transparent;
	}

	#navbar-toggle.active span:before,
	#navbar-toggle.active span:after {
		top: 0;
	}

	#navbar-toggle.active span:before {
		transform: rotate(45deg);
	}

	#navbar-toggle.active span:after {
		transform: rotate(-45deg);
	} */
}

/* 
 */
body {
	/* background-color: rgb(248, 249, 250) !important; */
}


.account_home .dropdown-toggle.nav-link .icon_account_gray {
	margin: auto;
}

.input-group .button .btn {
	position: relative;
	z-index: 2;
	color: #fff;
	background-color: #ffc107;
	border-radius: 0;
	/* padding-bottom: 2px; */
}

.input-group .button .btn :hover {
	/* background-color: #fb8800; */
}

.account_home .btn {
	text-align: center;
	border-radius: 0;
	width: 100%;
	margin-top: 0.5rem;
	height: 30px;
	line-height: 18px;
	font-size: 1rem;
	color: #fff;
	background-color: #ffc107;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 700;

}

#sb-nav-fixed_ .page {
	display: flex;
	height: calc(100vh - 64px);
	flex-direction: column;
	justify-content: space-between;
}

#header .company {
	height: 50px;
}

#header .company .companyinfo .navbar_logo {
	/* width: 36px; */
	height: 60px;
	margin-top: -10px;
}

#header .company .companyinfo {
	color: #FFFFFF;
	font-size: 1.75rem;
	font-weight: 600;
	white-space: nowrap;
	display: flex;
	/* height: 100%; */
	align-items: center;
}

#header .company .companyinfo h1 {
	color: #FFFFFF;
	font-size: 1.75rem;
	font-weight: 600;
	white-space: nowrap;
	display: flex;
	/* height: 100%; */
	align-items: center;
}

#header .menu .bnt_search {
	width: 64px;
	border-radius: 20px !important;
	margin: 2px !important;
}

.search-input .noboder {
	/* background-color: #FDFFAE; */
	border: none !important;
	margin-left: 14px;
	padding-left: 0;
	height: 100%;
}

.hot_ {
	color: #000;
	margin-top: 0.4rem;
	min-height: 27px;
	text-align: center;
}

.hot_ span {
	color: #fb8800;
	font-size: 1rem;
	font-weight: 400;
	white-space: nowrap;
	line-height: 26px;
}

#header .menu .gio_hang {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	text-decoration: none;
	color: #fff
}

#header .menu .cart_number {
	left: 75% !important;
}

#header .menu .gio_hang .icon_nofi_gray {
	margin: auto;
}

@media screen and (min-width: 1640px) {
	.categorory_dropdown {
		width: 50% !important;
		margin-left: -10rem !important;
	}
}

@media screen and (max-width: 991px) {}

/*  */


.search-input {
	/* border-radius: 20px; */

	border: 3px solid orange;
	background-color: #FFF;
	height: 40px;
	height: 40px;
}

.form-control:focus {
	box-shadow: none !important;
	/* background-color: #fbfcc5 !important; */
	background-color: #FFF !important;

}

.account_home {
	display: flex;
	flex-direction: column;
	align-items: center;
}

/* 
 */

.hotline {

	align-items: center;
	background: #fff;
	/* background-color: var(--bg-main); */
	/* background: linear-gradient(45deg, #fef141, #74b4fe); */
	/* margin: 0px 10px; */
	display: flex;
	padding: 9px 0px;
	padding-top: 20px;
	flex-wrap: inherit;
	height: 48px;
	justify-content: space-between;
	top: 0;
	width: 100% !important;
	z-index: 1;

}

.hotline a {
	color: #5a5a5a;
	text-decoration: none !important;
	font-size: 1rem;
}

.hotline a:hover {
	/* color: #ffffff; */
}

.icon_ {
	/* padding-right: 1rem; */
	/* font-size: 20px; */
}

.menu_nav {
	background-color: var(--menu-btn);
	font-weight: 600;
	/* #1769b4; */
	padding: 10px;
	border-radius: 50px;
	color: #FFF;
}

.text_menu {
	visibility: hidden;
	width: 120px;
	min-height: 500px;
	background-color: yellow;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px;
	position: absolute;
	z-index: 9;
	/* bottom: 125%; */
	/* Hiển thị tooltip phía trên mục gốc */
	left: 50%;
	transform: translateX(-50%);
	opacity: 0;
	transition: opacity 0.3s, visibility 0.3s;
}

.menu_nav :hover .text_menu {
	background-color: red;
	visibility: visible;
	opacity: 1;
	z-index: 9;
	position: absolute;

}

.hide {
	display: none;
}

.myDIV:hover+.hide {
	display: block;
	color: red;

}

@media (max-width:380px) {
	#header .company .companyinfo .navbar_logo {
		/* width: 36px; */
		display: none;
		height: 60px;
		margin-top: -10px;
	}


	#header .company .companyinfo .navbar_logo_mobile {
		/* width: 36px; */
		height: 4rem;
		margin-top: -5px;
		display: block !important;
	}

	.menu .row-item-center {
		margin-left: -70px;
	}

	.account_home {
		flex-direction: row !important;
	}

	nav ul {
		display: none;
		margin-left: 50%;
	}
}

#header .company .companyinfo .navbar_logo_mobile {
	display: none;
}

.menu {
	padding-top: 2px;
}

.menunav {
	position: relative;
	z-index: 3;
	background-color: var(--bg-main);
	color: #222;
	padding-right: 15px;
	list-style-type: none
}

.menunav ul li {
	color: #000;
	list-style: none;
	list-style-type: none;
	font-size: 1rem;
}